import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormControl} from '@angular/forms';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {SliderItems} from './slider.content';
import {NotifierService} from 'angular-notifier';
import {SocketService} from '../socket.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  data: any = {};
  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  index = 0;
  direction = 'right';

  /**
   * Constructor
   * @param {ApiService} api  Api service
   * @param {Router} router   Router
   * @param {AuthService} auth Auth service
   * @param {SliderItems} sliderItems Slider Items
   * @param {NotifierService} notifier Notifier service
   * @param {SocketService} socket
   */

  constructor(
    private readonly api: ApiService,
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly sliderItems: SliderItems,
    private readonly notifier: NotifierService,
    private readonly socket: SocketService
  ) {}

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/analytics']);
    }
  }

  login() {
    this.api.post('user/login', this.loginForm.value).subscribe(
      res => {
        const result = res.json();
        if (result && result.user) {
          this.auth.setToken(result.accessToken);
          this.auth.setUserRole(result.user.role);
          // eslint-disable-next-line no-undef
          localStorage.setItem('user', JSON.stringify(result.user));
          this.socket.connect();
          this.notifier.notify('success', 'Login success');
          if (result.user.role === 'customer') {
            this.router.navigate(['/analytics']);
          } else {
            this.router.navigate(['/']);
          }
        } else {
          this.notifier.notify('error', result.message);
        }
      },
      err => {
        const res = err.json();
        this.notifier.notify('error', res.message);
      }
    );
  }
}
