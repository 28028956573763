import {Component, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Inject} from '@angular/core';

@Component({
  selector: 'app-link-popup',
  templateUrl: './link-popup.component.html',
  styleUrls: ['./link-popup.component.css'],
})
export class LinkPopupComponent implements OnInit {
  title?: string;
  link?: string;

  constructor(
    private readonly dialogRef: MatDialogRef<LinkPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: any
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.link = this.data.url;
  }

  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }
}
