import {Routes} from '@angular/router';

import {FullComponent} from './layouts/full/full.component';
import {
  AuthGuard,
  AdminGuard,
  ClientGuard,
} from './shared/auth/auth.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: FullComponent,

    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        redirectTo: '/people',
        pathMatch: 'full',
      },
      {
        path: 'login',
        loadChildren: './shared/shared.module#SharedModule',
        pathMatch: 'full',
      },
      {
        path: 'users',
        canActivate: [AuthGuard, AdminGuard],
        loadChildren: './user/user.module#UserModule',
      },
      {
        path: 'account',
        canActivate: [AuthGuard],
        loadChildren: './account/account.module#AccountModule',
      },
      {
        path: 'organization',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren:
          './organization-group/organization-group.module#OrganizationGroupModule',
      },
      {
        path: 'people',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './people/people.module#PeopleModule',
      },
      {
        path: 'customer',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './customer/customer.module#CustomerModule',
      },
      {
        path: 'people-categories',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren:
          './people-category/people-category.module#PeopleCategoryModule',
      },
      {
        path: 'people-groups',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './people-group/people-group.module#PeopleGroupModule',
      },
      {
        path: 'fleets',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './fleet/fleet.module#FleetModule',
      },
      {
        path: 'devices',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './device/device.module#DeviceModule',
      },
      {
        path: 'geofences',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './geofence/geofence.module#GeofenceModule',
      },
      {
        path: 'stores',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './store/store.module#StoreModule',
      },
      {
        path: 'store-categories',
        canActivate: [AuthGuard],
        loadChildren:
          './store-category/store-category.module#StoreCategoryModule',
      },
      {
        path: 'store-groups',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './store/store.module#StoreModule',
      },
      {
        path: 'payment',
        canActivate: [AuthGuard, ClientGuard],
        loadChildren: './payment/payment.module#PaymentModule',
      },
      {
        path: 'visits',
        canActivate: [AuthGuard],
        loadChildren: './visit/visit.module#VisitModule',
      },
      {
        path: 'opportunities',
        canActivate: [AuthGuard],
        loadChildren: './opportunity/opportunity.module#OpportunityModule',
      },
      {
        path: 'projects',
        canActivate: [AuthGuard],
        loadChildren: './project/project.module#ProjectModule',
      },
      {
        path: 'project-questions',
        canActivate: [AuthGuard],
        loadChildren:
          './project-question/project-question.module#ProjectQuestionModule',
      },
      {
        path: 'answers',
        canActivate: [AuthGuard],
        loadChildren:
          './answer/answer.module#AnswerModule',
      },
      {
        path: 'analytics',
        canActivate: [AuthGuard],
        loadChildren: './analytics/analytics.module#AnalyticsModule',
      },
      {
        path: 'log',
        canActivate: [AuthGuard],
        loadChildren: './log/log.module#LogModule',
      },
    ],
  },
];
