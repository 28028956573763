import {Component, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Inject} from '@angular/core';

@Component({
  selector: 'app-change-activity-status-popup',
  templateUrl: './change-activity-status-popup.component.html',
  styleUrls: ['./change-activity-status-popup.component.css'],
})
export class ChangeActivityStatusPopupComponent implements OnInit {
  type = '';
  fromDate: Date = new Date();
  toDate: Date | null = null;
  isManual = true;
  useEmail = false;
  useSMS = false;
  comment = '';

  constructor(
    private readonly dialogRef: MatDialogRef<
      ChangeActivityStatusPopupComponent
    >,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: any
  ) {}

  ngOnInit() {
    this.type = this.data.type;
  }

  onSubmit() {
    this.dialogRef.close({
      fromDate: this.fromDate,
      toDate: this.toDate,
      use: {
        email: this.useEmail,
        sms: this.useSMS,
      },
      comment: this.comment,
    });
  }
}
