import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

const MENUITEMS = [
  { state: '/organization', name: 'Organization', type: 'link', icon: 'people' },
  { state: '/people', name: 'People', type: 'link', icon: 'person' },
  { state: '/customer', name: 'Customer', type: 'link', icon: 'work' },
  { state: '/people-groups', name: 'People Groups', type: 'link', icon: 'groups' },
  { state: '/people/vendors', name: 'Vendors', type: 'link',  icon: 'person_outline' },
  { state: '/fleets', name: 'Fleets', type: 'link', icon: 'account_balance' },
  { state: '/devices', name: 'Smartphones', type: 'link', icon: 'settings_remote' },
  { state: '/geofences', name: 'Geofences', type: 'link', icon: 'terrain' },
  { state: '/stores', name: 'Stores', type: 'link', icon: 'store' },
  { state: '/store-groups', name: 'Store Groups', type: 'link', icon: 'business' },
  { state: '/visits', name: 'Visits', type: 'link', icon: 'pages' },
  { state: '/projects', name: 'Projects', type: 'link', icon: 'flag' },
  { state: '/project-questions', name: 'Questions', type: 'link', icon: 'help_center' },
  { state: '/answers', name: 'Survey', type: 'link', icon: 'format_list_numbered' },
  { state: '/analytics', name: 'Analytics', type: 'link', icon: 'bar_chart' },
  { state: '/payment', name: 'Payment', type: 'link', icon: 'settings_applications' },
  { state: '/log', name: 'System Logs', type: 'link', icon: 'track_changes' },
];

const MENUITEMSFORADMIN = [
  { state: '/users', name: 'Store Users', type: 'link', icon: 'group' },
];

const MENUITEMSFORCUSTOMER = [
  { state: '/account', name: 'Account', type: 'link', icon: 'settings' },
  // { state: '/visits', name: 'Visits', type: 'link', icon: 'pages' },
  // { state: '/projects', name: 'Projects', type: 'link', icon: 'flag' },
  { state: '/analytics', name: 'Analytics', type: 'link', icon: 'bar_chart' },
];

const MENUITEMSFORVENDOR = [
  { state: '/account', name: 'Account', type: 'link', icon: 'settings' },
  // { state: '/opportunities', name: 'Opportunities', type: 'link', icon: 'flag' },
  // { state: '/visits', name: 'Visits', type: 'link', icon: 'pages' },
  { state: '/analytics', name: 'Analytics', type: 'link', icon: 'bar_chart' },
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }

  getMenuitemForAdmin(): Menu[] {
    return MENUITEMSFORADMIN;
  }

  getMenuitemForCustomer(): Menu[] {
    return MENUITEMSFORCUSTOMER;
  }
  
  getMenuitemForVendor(): Menu[] {
    return MENUITEMSFORVENDOR;
  }
}
