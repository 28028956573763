import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import {Injectable} from '@angular/core';

import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userRole = this.authService.getUserRole();
    if (userRole === 'admin') {
      return true;
    } else if (userRole === 'client') {
      this.router.navigateByUrl('/analytics');
    } else if (userRole === 'driver') {
      this.router.navigateByUrl('/driver-edit');
    }
    return false;
  }
}

@Injectable()
export class ClientGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userRole = this.authService.getUserRole();
    if (userRole === 'client') {
      return true;
    } else if (userRole === 'admin') {
      this.router.navigateByUrl('/users');
    } else if (userRole === 'driver') {
      this.router.navigateByUrl('/driver-edit');
    }
    return false;
  }
}

@Injectable()
export class DriverGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userRole = this.authService.getUserRole();
    if (userRole === 'driver') {
      return true;
    } else if (userRole === 'admin') {
      this.router.navigateByUrl('/users');
    } else if (userRole === 'client') {
      this.router.navigateByUrl('/analytics');
    }
    return false;
  }
}
