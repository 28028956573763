import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import {ReactiveFormsModule} from '@angular/forms';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import {GoogleMapsAPIWrapper, AgmCoreModule} from '@agm/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {ColorPickerModule} from 'ngx-color-picker';
import {NotifierModule} from 'angular-notifier';

import {AppRoutes} from './app.routing';
import {AppComponent} from './app.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FullComponent} from './layouts/full/full.component';
import {AppHeaderComponent} from './layouts/full/header/header.component';
import {AppSidebarComponent} from './layouts/full/sidebar/sidebar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DemoMaterialModule} from './demo-material-module';
import {SharedModule} from './shared/shared.module';
import {SpinnerComponent} from './shared/spinner.component';
import {ReverseGeocodeDirective} from './analytics/reverse-geocode.directive';
import {customNotifierOptions} from './shared/notification/notifier.config';
import {SocketIoModule} from 'ngx-socket-io';
import {SocketIO} from './shared/socket.custom';
import {
  AdminGuard,
  AuthGuard,
  ClientGuard,
  DriverGuard,
} from './shared/auth/auth.guard';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    ReverseGeocodeDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ColorPickerModule,
    DemoMaterialModule,
    NgSelectModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    HttpModule,
    SharedModule,
    NotifierModule,
    NotifierModule.withConfig(customNotifierOptions),
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes),
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey,
      libraries: ['places', 'drawing', 'geometry'],
    }),
    SocketIoModule,
    NgbModule.forRoot(),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    GoogleMapsAPIWrapper,
    SocketIO,
    AuthGuard,
    AdminGuard,
    ClientGuard,
    DriverGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
