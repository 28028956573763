export const environment = {
  production: true,
  apiUrl: 'https://store-admin-api-dev.beam.live/api/v1',
  apiKey: 'AIzaSyBdiNxE31mGELQwmau05oOcld_1Kk2ED0s',
  gpsApiUrl: 'https://store-gps.beam.live/api/v1',
  socketUrl: 'https://store-admin-api-dev.beam.live',
  stripeKey: 'pk_test_Et647M9Zop5eCXjCXiuFawOV',
  siteUrl: 'https://store-admin.beam.live/',
  pageUrl: 'https://store.beam.live/',
};
